<template>
	<div>
		<load v-if="loader" color="#2474b2" size="70" />
		<div v-else>
			<div class="login_cpf">
				<ValidationObserver ref="form">
					<form class="modal_login row" @submit.prevent="loginStep">
						<div class="col-md-12 my-2">
							<label class="label_default mb-0">
								Qual seu
								<strong>CPF ou CNPJ?</strong>
							</label>
						</div>

						<ValidationProvider v-slot="{ classes, validate }"
							:rules="{ required: true, min: CPF.length <= 11 ? 11 : 14 }" name="CPF" tag="div" :bails="false"
							class="col-md-12 my-1" id="loginInputContainer">
							<the-mask type="text" id="inputLogin" class="input_default form-control imputsMaxHeigth"
								:mask="['###.###.###-##', '##.###.###/####-##']" @blur.native="validate($event)" v-model="CPF"
								placeholder="..." :class="classes" :masked="false" />

								<div>
									<button
										type="submit"
										class="btn_default px-2 py-2 imputsMaxHeigth"
										:disabled="disabled"
										id="buttonCpfLogin"
										@click="getUserWithCpf"
									>
										<span
											class="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
											v-show="disabled"
										></span>
										{{ !disabled ? ">" : "" }}
									</button>
								</div>
						</ValidationProvider>

						<div class="col-md-12 my-2">
							<label class="label_default mb-0">
								Escolha a
								<strong>Unidade</strong>
							</label>
						</div>
						<ValidationProvider v-slot="{ errors, ariaMsg, classes }" rules="required" name="Unidade" tag="div"
							:bails="false" class="col-md-12 my-1 unidadeMobile">
							<select id="selectLogin" class="input_default form-control imputsMaxHeigth" v-model="unidadeSelect" :class="classes" :disabled="disabledUnidade" @change="selectUnidade">
								<option :value="{ tenancyName: 'master', id: 0 }">MASTER</option>
								<option :value="item" v-for="(item, index) in unidades" :key="index">{{ item.tenancyName }}</option>
							</select>
							<span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
						</ValidationProvider>


						<div class="col-md-12 mb-2 mt-3" id="orderKeyCont">
							<label class="label_default mb-0">
								Digite sua
								<strong>Senha</strong>
							</label>
						</div>
						<ValidationProvider
							v-slot="{ errors, ariaMsg, classes }"
							rules="required|min:5"
							name="Senha"
							tag="div"
							:bails="false"
							class="col-md-12 mb-3 senhaMobile"
							:disabled="disabled"
						>
							<div style="margin-bottom:10px;" id="msg" class="d-none">
								<span style="font-size: 14px;" class="alerta"
									>O Caps-Lock está ativado!</span
								>
							</div>

							<input
								:class="classes"
								type="password"
								class="px-2 py-2 input_default form-control imputsMaxHeigth"
								placeholder="****"
								v-model="senha"
								:disabled="disabledInputPassword"
								id="txtSenha"
							/>

							<span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
						</ValidationProvider>


						<button
							type="submit"
							class="btn_default px-2 py-2"
							:disabled="disabled"
							id="buttonSubmitLogin"
						>
							<span
								class="spinner-border spinner-border-sm imputsMaxHeigth"
								role="status"
								aria-hidden="true"
								v-show="disabled"
							></span>
							{{ !disabled ? "Entrar" : "" }}
						</button>


						<div class="col-md-12 my-1">
							<p class="esqueci_senha">
								Esqueceu a senha?
								<a href="#" @click.prevent="esqueciSenha()">Clique aqui.</a>
							</p>
						</div>
						<div class="routeCont">
							<router-link :to="'/unidadeCpf'" class="outra-conta" v-if="this.sizeWindow < 768">
									{{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}
									<strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
							</router-link>
						</div>

					</form>
				</ValidationObserver>
			</div>
		</div>
	</div>
</template>

<script>
import load from '@/components/utils/_Load'
import { faThList } from '@fortawesome/free-solid-svg-icons'
import { TheMask } from 'vue-the-mask'

export default {
	components: {
		load,
		TheMask
	},
	data() {
		return {
			disabled: false,
			disabledCpf: false,
			disabledUnidade: true,
			disabledInputPassword: true,
			CPF: '',
			senha: '',
			mensagem: '',
			unidades: [],
			unidadeSelect: '',
			isAdmin: false,
			loader: false,
			sizeWindow: 0
		}
	},
	mounted() {
		this.sizeWindow = $(window).width()
		$('#txtSenha').keyup(function (e) {
			var key = e.charCode || e.keyCode
			var tamanho = this.value.length
			var ultimoCaracter = this.value.substring(tamanho - 1)

			if (tamanho === 0) {
				$('#msg').addClass('d-none')
				return false
			}

			if (key === 20 && tamanho !== 0) {
				$('#msg').toggleClass('d-none')
				return false
			}

			// Verifica se é maiúsculo, e se não é shift
			if (
				ultimoCaracter.toUpperCase() === ultimoCaracter &&
				ultimoCaracter.toLowerCase() !== ultimoCaracter
			) {
				$('#msg').removeClass('d-none')
			}
		})

		this.$store
			.dispatch('getTenants', this.idsUnidades)
			.then(resolve => {
				this.unidades = resolve
				this.loader = false
			})
			.catch(reject => { })
	},
	methods: {
		getUserWithCpf() {
			this.disabled = true;
			this.$store.dispatch('cpfForLogin', this.CPF).then(resolve => {
				if (!resolve.erro) {
					this.disabled = false;
					this.disabledUnidade = false;
					this.$parent.gratientAnimator(
						'linear-gradient(135deg, #e0a638 0%, #f38235 100%)'
					);

					let idsUnidades = []

					resolve.result.forEach(user => {
						if (user.profile === 1) {
							idsUnidades.push(0);
							this.isAdmin = true;
						} else {
							idsUnidades.push(user.tenant.id);
						}
					});
					this.unidades = this.unidades.filter(unidade => idsUnidades.includes(unidade.id));
					this.updateUnidades = true;
				} else {
					this.$notify({
						group: 'erros',
						type: 'error',
						text: `<i class="icon ion-close-circled"></i>${resolve.message}`
					});
					this.disabled = false;
				}
			});
		},
		selectUnidade() {
			this.$store.commit('set_tenant', this.unidadeSelect)

			if (faThList.user === undefined && this.$store.state.login.usersForLogin.length > 1) {
				let user = this.$store.state.login.usersForLogin.filter(user => user.tenant.id === this.unidadeSelect.id)

				if (user) {
					this.$store.commit('set_user', user[0])
				}
			}

			this.disabledInputPassword = false
			console.log(this.unidadeSelect)
		},
		esqueciSenha() {
			this.$loading()
			this.$store.dispatch('gerateReset').then(resolve => {
				this.$loading.close()
				if (resolve) {
					this.$router.push('/esqueci-a-senha')
				}
			})
		},
		loginStep() {
			this.$refs.form.validate().then(success => {
				if (success) {
					this.disabled = true
					this.$store.dispatch('gerateToken', this.senha).then(resolve => {
						if (!resolve.error) {
							this.disabled = false

							this.$parent.gratientAnimator(
								'linear-gradient(to right, #e0a638 0%,#f38235 100%)'
							)
							this.$store.dispatch('getServices')
							this.$store.dispatch('getSuporte')
							this.$store.commit('SET_TIME_START', new Date().getTime())
							if (this.$store.getters.getUser.profile != 3) {
								this.$router.push('/')
							} else {
								this.$router.push('/abrir-caixa')
							}
						} else {
							this.toast(`${resolve.message}`, this.$toast.error)
							this.disabled = false
						}
					})
				}
			})
		}
	},
	watch: {
		unidadeSelect() { }
	},
	computed: {
		user() {
			return this.$store.state.login.userLogin
		}
	}
}
</script>


<style scoped>
label {
	font-size: 16px;
}

span {
	font-size: 12px;
	padding-top: 3px;
}

@media screen and (max-width:767px) {
	.input_default {
		width: 100%;
		text-align: center;
		background-color: #ffffff !important;
		font-weight: 500 !important;
		border: 1px solid #98C4E6;
		border-radius: 7px !important;
		height: calc(2.5em + 0.75rem + 2px);
	}

	#buttonSubmitLogin {
		margin-top: 0 !important;
		margin-bottom: 0.5rem !important;
		height: 40px;
		width: 100%;
		border-radius: 10px;
		font-weight: 500;
		border: 1px solid #2474b2;
		background-color: #2474b2;
		color: #ffff;
		box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
		transition: all 0.2s linear;
		font-size: 18px;
	}

	#loginInputContainer {
		display: flex;
		width: 100%;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	#inputLogin {
		border-radius: 7px 0 0 7px !important;
	}
	#buttonCpfLogin {
		height: 100%;
		border-radius: 0 7px 7px 0 !important;
	}

	.select-login {
		padding: .5rem 0 .5rem 0 !important;
		min-height: 35px;
	}

	.unidadeMobile {
		padding: 0 !important;
	}
	.senhaMobile {
		padding: 0 !important;
	}
	.esqueci_senha {
		margin-bottom: .5rem !important;
	}
	.imputsMaxHeigth {
		max-height: 40px !important;
	}
}
</style>